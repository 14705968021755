import { cardsJS } from '../components/cards';

export default {
  init() {
    // JavaScript to be fired on the home page

    //Components
    cardsJS();
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },
};
